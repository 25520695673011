import React from "react"

class LinksMenu extends React.Component {
  constructor(props) {
    super(props)

    this.state = { open: false }
    this.toggle = this.toggle.bind(this)
  }

  toggle() {
    this.setState(state => ({ open: !state.open }))
  }

  render() {
    return (
      <div className="linksMenu">
        <div className="header" onClick={this.toggle}>
          {this.state.open && <div className="button minus"></div>}
          {!this.state.open && (
            <div>
              <div className="button plus"></div>
              <span className="text">Links</span>
            </div>
          )}
        </div>
        <div className={this.state.open ? "container open" : "container"}>
          {this.state.open && (
            <React.Fragment>
              <div className="references">
                <div className="italic">References</div>
                <div>
                  <a href="mailto:taylorlanzet@gmail.com" target="_blank">
                    Taylor Lanzet
                  </a>
                </div>
                <div>
                  <a href="mailto:thenanyu@gmail.com" target="_blank">
                    Nan Yu
                  </a>
                </div>
              </div>
              <div className="links">
                <div className="italic">Links</div>
                <div>
                  <a href="https://nat.uduwela.com" target="_blank">
                    Website
                  </a>
                </div>
                <div>
                  <a
                    href="https://www.notion.so/uduwela/17f2affc87ea43a58efe52113d34f94c?v=9d5f2efbf1dc4b4a92ade63d3541f75b"
                    target="_blank"
                  >
                    Portfolio
                  </a>
                </div>
                <div>
                  <a
                    href="https://nat.uduwela.com/natalie-uduwela-resume.pdf"
                    target="_blank"
                  >
                    Resume
                  </a>
                </div>
              </div>
            </React.Fragment>
          )}
        </div>
      </div>
    )
  }
}

export default LinksMenu
