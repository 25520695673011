import React from "react"
import Logo from "./../assets/pineapple-collaborative-logo-black.svg"
import LinksMenu from "./../components/linksMenu.js"

export default function Home() {
  return (
    <div className="page">
      <div className="header">
        <span className="blue">nat</span> <strong>+</strong>{" "}
        <a href="https://www.pineapplecollaborative.com/" target="_blank">
          <Logo />
        </a>
      </div>
      <div className="letter">
        <div>
          My entire existence is centered around the appreciation of food and
          flavor, and sharing that appreciation with those around me. To me,
          this looks like:
          <ul>
            <li>
              <span className="gintoNormalItalic blue">Eating…</span> when I
              visit my parents, and every single meal is planned and elaborately
              executed.
            </li>
            <li>
              <span className="gintoNormalItalic blue">Learning…</span> watching
              my dad cook, and vigorously taking notes as he assembles his Sri
              Lankan curries.
            </li>
            <li>
              <span className="gintoNormalItalic blue">Cooking…</span> when I
              return home, and try my best to make their meals for my friends
              and loved ones (of course the recipes are scribbled on index
              cards, with no ingredient amounts!)
            </li>
            <li>
              <span className="gintoNormalItalic blue">Creating…</span>{" "}
              desperately trying to re-create my dad's infamous chili sauce,
              which he once made in a frenzy from an excess of hybrid peppers
              grown in our backyard.
            </li>
          </ul>
        </div>

        <p>
          Your mission to create community, centered around food and the table,
          could not resonate more. Plus, I’m obsessed with your brand and use of
          typography… please pass my kudos to your designer!
        </p>

        <p>
          For my qualifications: I studied design and typography, and have
          worked as a software engineer for 7+ years in technology startups. I’m
          now looking for a project and a team that’s more inspiring, and rooted
          in something I’m more passionate about (food!). I’m well versed in{" "}
          <span className="gintoNormalRegular blue">React</span>,{" "}
          <span className="gintoNormalRegular blue">Typescript</span>,{" "}
          <span className="gintoNormalRegular blue">Git</span>,{" "}
          <span className="gintoNormalRegular blue">CircleCI</span>, and know my
          way around{" "}
          <span className="gintoNormalRegular blue">Contentful </span>
          (API driven CMS) and{" "}
          <span className="gintoNormalRegular blue">Shopify</span>.
        </p>

        <p>
          I’m incredibly detail oriented, take feedback well, love solving
          complex problems, and generally just get stuff done (and do it well).
          Oh also, my last two jobs were remote, so I know the ins and outs of
          collaborating with a distributed team.
        </p>

        <p>
          I’d love the opportunity to be considered for your web developer
          position. Let me know if you’d like to chat more!
        </p>

        <p>
          Best,
          <br />
          <span className="viksjoe blue">nat</span>
        </p>
      </div>
      <LinksMenu />
    </div>
  )
}
